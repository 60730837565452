import { render, staticRenderFns } from "./Hangman.vue?vue&type=template&id=3d0f5398&scoped=true&lang=pug&"
import script from "./Hangman.vue?vue&type=script&lang=js&"
export * from "./Hangman.vue?vue&type=script&lang=js&"
import style0 from "./Hangman.vue?vue&type=style&index=0&id=3d0f5398&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d0f5398",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VBtn,VCard,VDialog,VDivider,VFadeTransition,VImg})
