<template lang="pug">
    ArticleWrapper(color="primary")
        v-dialog(v-model="showDialog" max-width="700" :overlay-color="dialogColor.backgroundColor" flat overlay-opacity="1", @click:outside="hideDialog", :key="currentTerm ? currentTerm.expression : 0")
          v-card(v-if="currentTerm", flat :color="dialogColor.backgroundColor").pa-5.text-center
            .d-flex.flex-column
              .flex-grow-1
                .meaning(:style="{color: dialogColor.color, 'text-shadow': '2px 2px rgba(0,0,0,0.7)'}")
                  .d-flex.align-start.justify-center
                    .quote.text-h4.text-md-h1.mr-2 “
                    .oblique.text-h4.text-md-h3(:class="{'display-1': $vuetify.breakpoint.smAndUp, 'display-2': $vuetify.breakpoint.mdAndUp}") {{currentTerm.meaning}}
                    .quote.text-h4.text-md-h1.ml-2 ”
              
                v-divider.my-2.my-md-12(dark)

                .text(:class="{'text-h4': $vuetify.breakpoint.mdAndUp}", :style="{color: 'rgba(255,255,255,0.5)'}") Which expression from <em>{{currentTerm.show ? currentTerm.show : 'our lesson'}}</em> matches this meaning?
                v-btn(x-large :color="dialogColor.color" block @click="hideDialog").mt-6 Start Guessing
              
              .flex-shrink-1.text-center.mt-12
                v-img(src="@/assets/logo_white_bg.png" contain height="80px" width="200px").mx-auto
                .white--text sericity.com
        
        v-fade-transition
          .game(v-show="ready")
            h1.text-h5.text-md-h4.mb-3.mb-md-12.font-weight-bold.white--text {{$route.params.show ? 'TV Show' : ''}} {{currentTerm ? currentTerm.show : null}} Expression Guessing Game
            template(v-if="$route.params.worksheetId && worksheet")
              Hangman(:title="worksheet.title", :subtitle="null", :keyExpressions="items", showRestart, @change="currentTerm = $event", @reset="showDialog=true", @restart="init")
            template(v-else)
              Hangman(:title="null", :subtitle="currentTerm ? `${currentTerm.show} – Season ${currentTerm.season} Ep. ${currentTerm.episode} : “${currentTerm.title}”` : null", :keyExpressions="items", showRestart, @change="currentTerm = $event", @reset="showDialog=true", @restart="init")
</template>

<script>
import ArticleWrapper from "@/components/Articles/ArticleWrapper.vue";
import Hangman from "@/components/Games/Hangman/Hangman.vue";
import { get, call } from "vuex-pathify";
export default {
  name: "HangmanGame",
  components: { ArticleWrapper, Hangman },
  data() {
    return {
      ready: false,
      currentTerm: null,
      showDialog: true,
    };
  },
  created() {
    this.init();
  },
  beforeDestroy() {
    this.setIsDarkPage(false);
  },
  computed: {
    ...get({
      lessons: "Lessons/lessons",
      worksheet: "Tools/worksheet",
    }),
    dialogColor() {
      const DEFAULT = {
        backgroundColor: "primary",
        color: "#03B39B",
      };
      if (!this.currentTerm) return DEFAULT;
      else if (this.currentTerm.show === "Ratched") return DEFAULT;
      else if (this.currentTerm.show === "Brooklyn Nine Nine")
        return {
          backgroundColor: "#000",
          color: "#FDF04C",
        };
      else if (this.currentTerm.show === "Avatar")
        return {
          backgroundColor: "#4E1711",
          color: "#FB9225",
        };
      else return DEFAULT;
    },
    items() {
      if (this.$route.params.worksheetId) {
        return this.worksheet ? this.worksheet.expressions : [];
      } else {
        if (!this.lessons) return [];

        const activeLessons = this.lessons.filter(
          (lesson) => lesson.keyExpressions && lesson.keyExpressions.length > 0
        );

        // console.log("activeLessons length", activeLessons.length);

        return activeLessons
          .map((lesson) =>
            lesson.keyExpressions.map((expression) => {
              return {
                show: lesson.show,
                season: lesson.season,
                episode: lesson.episode,
                title: lesson.title,
                ...expression,
              };
            })
          )
          .flat()
          .filter((itm) =>
            this.$route.params.show
              ? itm.show.toLowerCase() ===
                decodeURIComponent(this.$route.params.show).toLowerCase()
              : true
          );
      }
    },
  },
  methods: {
    ...call({
      updateLessons: "Lessons/updateLessons",
      updateWorksheet: "Tools/updateWorksheet",
      setIsDarkPage: "Nav/setIsDarkPage",
    }),
    async init() {
      this.ready = false;
      this.currentTerm = null;
      this.showDialog = true;
      if (this.$route.params.worksheetId)
        await this.updateWorksheet({ id: this.$route.params.worksheetId });
      else {
        await this.updateLessons();
      }
      this.setIsDarkPage(true);
    },
    hideDialog() {
      this.ready = true;
      this.showDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  box-shadow: unset;
}

::v-deep .v-main,
::v-deep .v-main__wrap {
  background-color: red !important;
}
</style>
